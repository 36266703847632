import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../../components/layouts/secondary-landing";
import SEO from "../../../../components/seo/seo";
import HeroChevron from "../../../../components/hero/hero-chevron";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../../components/faq/faq-accordion";
import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";
import Icon from "../../../../components/custom-widgets/icon";
import QuickLinksMortgage from "../../../../components/quick-links/quick-links-mortgage";
import MortgageCalculator from "../../../../components/calculators/mortgage-calculator/calculator";
import CustomLargeCards from "../../../../components/card/custom-large-cards";
import HelpSection from "../../../../components/home-loans/help-section";

import useGoTo from "../../../../hooks/use-goto";
import PreApprovalWorkflow from "../../../../components/home-loans/pre-approval-workflow";

const SpanishHomeLoans = () => {
  useGoTo();

  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/personal-banking/home-loans/hero-home-loans-02-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-what-is-a-portfolio-loan-04032023-730.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(relativePath: { eq: "cards/articles-cards/thumbnail-fixer-upper-091923.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/articles-cards/thumbnail-worth-it-to-refinance-06062023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/personalization/smart-start-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const seoData = {
    title: "Préstamos hipotecarios",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Préstamos hipotecarios"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Si está buscando prestamos para vivienda, aquí encontrará todos los tipos de préstamos hipotecarios bancarios que WaFd Bank tiene para usted."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/prestamos-hipotecarios"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-home-loan-02.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "es-home-loans-hero",
    ...getHeroImgVariables(pageData),
    altText: "Smiling couple sitting on the floor of their new house.",
    heroBody: {
      components: [
        {
          id: 1,
          subheading: {
            text: "WaFd Bank Préstamos Hipotecarios",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "loan-officer-cta",
            class: "btn-white",
            text: "Halla a un Experto",
            url: "/es/banca-personal/contacte-a-un-asesor-hipotecario"
          }
        },
        {
          id: 3,
          button: {
            containerClass: "mt-3",
            id: "rates-cta",
            class: "btn-light",
            text: "Vea Tarifas de Hoy",
            url: "/es/banca-personal/tasas-de-interes-hipotecarias-actuales"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca Personal"
    },
    {
      id: 2,
      active: true,
      title: "Préstamos Hipotecarios"
    }
  ];

  // Commented out links are pages that have not been translated to spanish yet.
  const quickLinksData = [
    {
      id: "ql-first-time-buyer",
      icon: "../../../../images/icons/first-time-buyer-icon-active.svg",
      hoverIcon: "../../../../images/icons/first-time-buyer-icon-gray-hover.svg",
      altText: "Comprador Primerizo",
      text: "Comprador Primerizo",
      // url: "/personal-banking/home-loans/first-time-home-buyer-loans",
      description:
        "Si estás comprando tu primer hogar, probablemente tengas muchas preguntas. Nosotros estamos aquí para ayudarte."
    },
    {
      id: "ql-refinancing",
      icon: "../../../../images/icons/refinance-icon-active.svg",
      hoverIcon: "../../../../images/icons/refinance-icon-gray-hover.svg",
      altText: "Refinanciamiento",
      text: "Refinanciamiento",
      // url: "/personal-banking/home-loans/mortgage-refinance",
      description:
        "¿Quieres reducir las mensualidades de tu hipoteca, pagar tu hipoteca más rápido, o sacar dinero para ese proyecto de remodelación?"
    },
    {
      id: "ql-home-mortgage-loans",
      icon: "../../../../../images/icons/home-mortgage-loans-icon-active.svg",
      hoverIcon: "../../../../../images/icons/mortgage-icon-gray-hover.svg",
      altText: "Préstamos Hipotecarios",
      text: "Préstamos Hipotecarios",
      // url: "/personal-banking/home-loans/home-mortgage",
      description:
        "WaFd Bank tiene más de cien años como un prestamista hipotecario de cartera, ayudando a generaciones de familias a tener su propio hogar."
    },
    {
      id: "ql-smart-start-loan",
      icon: "../../../../../images/icons/smart-start-pictogram-default.svg",
      hoverIcon: "../../../../../images/icons/smart-start-icon-gray-hover.svg",
      altText: "Hipoteca Smart Start",
      text: "Hipoteca Smart Start",
      url: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start",
      description:
        "Una casa a un precio accesible es una puerta al éxito financiero. Si tienes problemas calificando para una hipoteca convencional por tus ingresos, Smart Start es justo lo que necesitas."
    },
    {
      id: "ql-home-equity-heloc",
      icon: "../../../../../images/icons/home-equity-heloc-icon-active.svg",
      hoverIcon: "../../../../../images/icons/heloc-icon-gray-hover.svg",
      altText: "Línea de Crédito con Garantía Hipotecaria",
      text: "Línea de Crédito con Garantía Hipotecaria",
      // url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit",
      description:
        "Usa la liquidez que has construido en tu hogar para básicamente cualquier necesidad. Ofrecemos una línea de crédito o un préstamo asegurado por tu liquidez, también conocidos como HELOC y HELOAN."
    },
    {
      id: "ql-easy-move-cross-collateral",
      icon: "../../../../../images/icons/cross-collateral-pictogram-default.svg",
      hoverIcon: "../../../../../images/icons/cross-collateral-icon-gray-hover.svg",
      altText: "Hipoteca Colateral Cruzada Easy Move",
      text: "Hipoteca Colateral Cruzada Easy Move",
      // url: "/personal-banking/home-loans/easy-move-cross-collateral",
      description:
        "Compra tu casa nueva y preocúpate de vender tu casa actual después con la hipoteca colateral cruzada Easy Move."
    },
    {
      id: "ql-lot-loans",
      icon: "../../../../../images/icons/lot-loans-icon-active.svg",
      hoverIcon: "../../../../../images/icons/lot-loans-icon-gray-hover.svg",
      altText: "Préstamos para Lotes y Terrenos",
      text: "Préstamos para Lotes y Terrenos",
      url: "/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos",
      description:
        "Con un Préstamo para Lotes y Terrenos de WaFd Bank, obtén el financiamiento que necesitas para comprar el lugar pefecto para tu nuevo hogar."
    },
    {
      id: "ql-remodeling",
      icon: "../../../../../images/icons/remodeling-icon-active.svg",
      hoverIcon: "../../../../../images/icons/remodeling-icon-gray-hover.svg",
      altText: "Remodelación",
      text: "Remodelación",
      // url: "/personal-banking/home-loans/home-improvement-loans",
      description: "¡Invierte en valor futuro! No dejes que los arreglos grandes en casa arruinen tu liquidez."
    },
    {
      id: "ql-construction-loans",
      icon: "../../../../../images/icons/construction-icon-active.svg",
      hoverIcon: "../../../../../images/icons/construction-icon-gray-hover.svg",
      altText: "Préstamos para la Construcción",
      text: "Préstamos para la Construcción",
      // url: "/personal-banking/home-loans/construction-loans",
      description:
        "Con la hipoteca todo-en-uno de WaFd Bank, también llamado préstamo de construcción personalizado, o préstamo de construcción a permanente, WaFd Bank te ayudará a construir la casa de tus sueños."
    }
  ];

  const AVG_PRICE = 350000;

  const calculatorData = {
    title: "Calculadora de Préstamo Hipotecario",
    loanType: "Home Loan",
    loanTerm: "30 Year Fixed",
    interestRate: 0,
    estimatedPayment: 1181,
    purchasePrice: AVG_PRICE,
    downPaymentPercent: 20,
    downPaymentAmount: AVG_PRICE * 0.2,
    loanAmount: AVG_PRICE - AVG_PRICE * 0.2,
    disclaimersSectionClass: "py-0",
    isSpanish: true
  };

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Pareja joven sosteniendo las llaves de su casa nueva.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "Familia jugando con el marco de una fotografía en su casa nueva."
  };

  const largeCardsData = [
    {
      title: "Ahorre hasta $600 en Costos de Cierre con una Cuenta de Cheques de WaFd Bank*",
      disclaimer:
        "*Para calificar para el descuento de hasta $600, antes de que los documentos para cerrar la venta estén preparados debe (1) tener o abrir una cuenta de cheques con WaFd Bank, (2) registrarse en el programa EZ-Pay desde su cuenta de chequest de WaFd Bank, y (3) registrar el depósito directo de por lo menos una forma mensual de ingresos recurrentes.",
      mainButton: {
        url: "/es/banca-personal/prestamos-hipotecarios/ahorre-en-costos-de-cierre",
        title: "Descubre Cómo"
      }
    },
    {
      title: "Hipoteca Smart Start",
      subtitle:
        "Si eres un comprador primerizo, encontrar y comprar una casa puede ser intimidante en el mercado actual. Nosotros creemos en que una vivienda a un accesible es el secreto a el éxito financiero a corto y largo pazo, y con la hipoteca Smart Start podemos ayudarte a volver el sueño de ser propietario una realidad",
      mainButton: {
        url: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start#do-you-qualify-calculator",
        title: "Descubre Cómo"
      },
      secondaryButton: {
        url: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start",
        title: "Aprenda sobre Smart Start",
        icon: {
          name: "arrow-right"
        }
      }
    }
  ];

  const mortgageBenefitsList = [
    {
      id: 1,
      title: "Aprobación de Préstamos",
      content: (() => (
        <p>
          Potenciales compradores pueden encontrar más fácil calificar para un préstamo hipotecario a través de un
          prestamista hipotecario de cartera que con un prestamista tradicional.
        </p>
      ))()
    },
    {
      id: 2,
      title: "Flexibilidad",
      content: (() => (
        <p>
          Tenemos la habilidad de recalcular y reamortizar tus pagos hipotecarios si tienes una reducción grande de tu
          capital. Esta puede ser una gran opción para reducir tus pagos mensuales sin la dificultad y el gasto de un
          refinanciamiento.
        </p>
      ))()
    },
    {
      id: 3,
      title: "Casos Especiales",
      content: (() => (
        <p>
          Tenemos éxito con préstamos contigentes y prestatarios de activos líquidos. Colaterización cruzada que reduce
          o elimina enganches. Como prestamista de cartera, podemos invertir y prestar en donde otros bancos no.
        </p>
      ))()
    },
    {
      id: 4,
      title: "Programas de Asistencia para Proprietários",
      content: (() => (
        <p>
          WaFd le ofrece asistencia a prestatarios que experimentan dificultad en pagar su hipoteca.{" "}
          <Link
            id="home-loans-loan-assistance-link"
            to="/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios"
            className="position-relative stretched-link text-decoration-none"
          >
            Aprenda más sobre nuestra asistencia hipotecaria.
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
      ))()
    }
  ];

  const faqAccordionData = {
    id: "es-home-loans-faq-accordion",
    isSpanish: true,
    title: "Preguntas Frecuentes",
    seeMoreFaqsLink: "/es/banca-personal/prestamos-hipotecarios/preguntas-frecuentes",
    faqCategoryNames: ["Spanish Home Loans"],
    seeMoreFaqsText: "Preguntas Frecuentes sobre Hipotecas",
    showContactBar: false
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "Si eres un comprador primerizo y te has informado, pero aún quieres ayuda, acércate a nosotros. Estamos cerca, y listos para sentarnos contigo para resolver todas tus dudas.",
    hasTexasLink: true
  };

  return (
    <SecondaryLanding showMortgageNotification={true}>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <div id="csr-nav" className="mt-4 py-3 bg-gray-5">
        <div className="container">
          <div className="row mx-0">
            <div className="px-0 col-12 col-md-6 col-lg-4 py-1 text-left">
              <Link
                id="scroll-to-loan-types"
                to={"#loan-types"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                ¿Qué Tipo de Préstamo es el Mejor para tí?
              </Link>
            </div>

            <div className="d-none d-lg-block px-0 col-12 col-md-6 col-lg-4 py-1 text-center">
              <Link
                id="scroll-to-portfolio-lender-lg"
                to={"#portfolio-lender"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Beneficios de un Prestamista de Cartera
              </Link>
            </div>
            <div className="d-none d-md-block d-lg-none px-0 col-12 col-md-6 col-lg-4 py-1 text-right">
              <Link
                id="scroll-to-portfolio-lender-md"
                to={"#portfolio-lender"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Beneficios de un Prestamista de Cartera
              </Link>
            </div>
            <div className="d-block d-md-none px-0 col-12 col-md-6 col-lg-4 py-1 text-left">
              <Link
                id="scroll-to-portfolio-lender-sm"
                to={"#portfolio-lender"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Beneficios de un Prestamista de Cartera
              </Link>
            </div>

            <div className="d-none d-lg-block px-0 col-12 col-lg-4 py-1 text-right">
              <Link
                id="scroll-to-mortgage-calculator-lg"
                to={"#mortgage-calculator-section"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Calculadora Hipotecaria / Búscador de Tasas
              </Link>
            </div>
            <div className="d-block d-lg-none px-0 col-12 col-lg-4 py-1 text-left">
              <Link
                id="scroll-to-mortgage-calculator-sm"
                to={"#mortgage-calculator-section"}
                className="csr-nav-link text-gray-70 text-decoration-none"
              >
                <Icon name="arrow-down" lib="far" class="mr-2" />
                Calculadora Hipotecaria / Búscador de Tasas
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section id="loan-types" className="container pb-4">
        <h1>¿Qué Tipo de Préstamo es el Mejor para tí?</h1>
        <h3>
          Si estás comprando tu primera casa, o eres un inversionista experimentado, WaFd Bank es un prestamista de
          cartera que te acompañará en cada paso de tu camino.
        </h3>
      </section>
      <QuickLinksMortgage
        id="home-loans-quick-links"
        className="py-0"
        linksData={quickLinksData}
        rowClass="justify-content-lg-between"
        colClass="col-lg-auto"
        isSpanish
      />
      <MortgageCalculator {...calculatorData} />
      <PreApprovalWorkflow />
      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />
      <section id="portfolio-lender" className="bg-light section-padding-adjusted">
        <div className="container">
          <div className="d-flex row justify-content-between">
            <div className="col-md">
              <StaticImage
                src="../../../../images/thumbnail-portfolio-lender-benefits-011124.jpg"
                alt="Portfolio Lender 101: Why You Should Bank with a Portfolio Lender"
                placeholder="blurred"
                className="mb-3 "
                quality="100"
              />
            </div>
            <div className="col-md">
              <h3 className="text-success">
                Unas Razones Por las que un Prestamista Hipotecario de Cartera como WaFd Bank es una Gran Ventaja
              </h3>
              {mortgageBenefitsList.map((mb, idx) => (
                <div className="d-flex align-items-start" key={mb.id}>
                  <Icon class="text-success fa-lg mr-2 mt-1" lib="fas" name="check-circle" />
                  <div>
                    <div className="text-success font-weight-bold">{mb.title}</div>
                    <p>{mb.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default SpanishHomeLoans;
